



































import { Component, Prop } from 'vue-property-decorator';
import ErrorMessageHandlerMixin from '../../misc/ErrorMessageHandler.mixin';
import { mixins } from 'vue-class-component';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { namespace } from 'vuex-class';
import Manager from '@/models/Manager';
import { managerStoreActions } from '@/store/manager.store';

const ManagerStore = namespace('manager');

@Component({
    mixins: [validationMixin],
    validations: {
        managerCopy: {
            login: {
                required
            },
            email: {
                required
            }
        }
    }
})
export default class EditManagerStatusComponent extends mixins(ErrorMessageHandlerMixin) {
    @Prop({ required: true })
    public manager!: Manager;

    @ManagerStore.Action(managerStoreActions.CHANGE_STATUS)
    public changeStatusAction!: (manager: Manager) => Promise<Manager>;

    public isLoading: boolean = false;

    public async updateStatus() {
        try {
            this.isLoading = true;

            await this.changeStatusAction(this.manager);
            this.$notifySuccessSimplified('MANAGERS.UPDATE_STATUS.STATUS_CHANGED');
            this.dismiss(true);
        } catch (e) {
            this.$notifyErrorSimplified('MANAGERS.UPDATE_STATUS.STATUS_CHANGED_ERROR');
        } finally {
            this.isLoading = false;
        }
    }

    public dismiss(reloadData: boolean = false) {
        this.$emit('closeDialog', reloadData);
    }
}

